import { Label } from "~/components/ui/label";
import { Slider } from "~/components/ui/slider";
import { SettingsDescription, SettingsRow } from "~/pages/settings/layout";
import { charsToLength, lengthName, lengthToChars, type SummaryLength } from "~/pages/settings/source";
import { SourceTypeSettings } from "~/sources/config";
import { DEFAULT_MAX_TOPIC_CHARS, DEFAULT_MAX_TOPICS, DEFAULT_POSTS, type RedditSourceConfig } from "./config";

SourceTypeSettings.method(
  "reddit",
  ({ config, setConfig }: { config: RedditSourceConfig; setConfig: (config: RedditSourceConfig) => void }) => {
    const redditConfig = config as RedditSourceConfig;

    const posts = redditConfig.posts ?? DEFAULT_POSTS;
    const maxTopicsPerPost = redditConfig.maxTopicsPerPost ?? DEFAULT_MAX_TOPICS;
    const maxTopicChars = redditConfig.maxTopicChars ?? DEFAULT_MAX_TOPIC_CHARS;
    const summaryLength = charsToLength(maxTopicChars);

    return (
      <>
        <SettingsRow>
          <Label>Top posts to summarize</Label>
          <div className="grow" />
          <div className="text-sm">{posts}</div>
          <div className="w-32 shrink-0">
            <Slider
              defaultValue={[posts]}
              min={1}
              max={30}
              step={1}
              onValueChange={([value]) => setConfig({ ...redditConfig, posts: value })}
            />
          </div>
        </SettingsRow>

        <SettingsRow>
          <Label>Discussion themes</Label>
          <div className="grow" />
          <div className="text-sm">{maxTopicsPerPost === 0 ? "None" : maxTopicsPerPost}</div>
          <div className="w-32 shrink-0">
            <Slider
              defaultValue={[maxTopicsPerPost]}
              min={0}
              max={10}
              step={1}
              onValueChange={([value]) => setConfig({ ...redditConfig, maxTopicsPerPost: value })}
            />
          </div>
        </SettingsRow>
        <SettingsDescription>The number of discussion themes to summarize per post.</SettingsDescription>

        <SettingsRow>
          <Label>Summary length</Label>
          <div className="grow" />
          <div className="text-sm">{lengthName(summaryLength)}</div>
          <div className="w-32 shrink-0">
            <Slider
              defaultValue={[summaryLength]}
              min={1}
              max={3}
              step={1}
              onValueChange={([value]) =>
                setConfig({ ...redditConfig, maxTopicChars: lengthToChars(value as SummaryLength) })
              }
            />
          </div>
        </SettingsRow>
      </>
    );
  },
);
