import { getDefaultSourceConfig, getReusableSummaryConfigKey, getSourceMeta } from "~/sources/config";

getSourceMeta.method("reddit", (_) => ({
  name: "Reddit",
  description: "Get a summary of Subreddit's posts.",
  icon: "/images/reddit.svg",
  iconMono: "/images/reddit-mono.svg",
  authPath: "/auth/reddit",
  alwaysShowSourceName: true,
}));

export const MIN_TOPIC_CHARS = 32;
export const DEFAULT_POSTS = 10;
export const DEFAULT_MAX_TOPICS = 3;
export const DEFAULT_MAX_TOPIC_CHARS = 300;

export interface RedditSourceConfig extends PrismaJson.SourceConfig {
  subreddit: string;
  posts?: number;
  maxTopicsPerPost?: number;
  maxTopicChars?: number;
}

getDefaultSourceConfig.method("reddit", (_) => {
  const config: Partial<RedditSourceConfig> = {
    viewMode: "grid",
    posts: DEFAULT_POSTS,
    maxTopicsPerPost: DEFAULT_MAX_TOPICS,
    maxTopicChars: DEFAULT_MAX_TOPIC_CHARS,
  };
  return config;
});

getReusableSummaryConfigKey.method("reddit", (source) => {
  const config = source.config as RedditSourceConfig;
  const configKeys = [
    `${config.subreddit}`,
    `posts:${config.posts || DEFAULT_POSTS}`,
    `topics:${config.maxTopicsPerPost || DEFAULT_MAX_TOPICS}`,
    `maxChars:${config.maxTopicChars || DEFAULT_MAX_TOPIC_CHARS}`,
  ];
  return configKeys.join("-");
});
