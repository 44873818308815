import { getDefaultSourceConfig, getSourceMeta } from "~/sources/config";

getSourceMeta.method("bsky", (_) => ({
  name: "Bluesky",
  description: "Get a summary of Bluesky feeds.",
  icon: "/images/bsky.svg",
  iconMono: "/images/bsky-mono.svg",
  authDialog: true,
  singleton: true,
}));

export const DEFAULT_MAX_TOPICS_PER_FEED = 3;
export const DEFAULT_MAX_TOPIC_CHARS = 300;

export interface BskySourceConfig extends PrismaJson.SourceConfig {
  maxTopicsPerFeed?: number;
  maxTopicChars?: number;
}

getDefaultSourceConfig.method("bsky", (_) => {
  const config: BskySourceConfig = {
    viewMode: "list",
    maxTopicsPerFeed: DEFAULT_MAX_TOPICS_PER_FEED,
    maxTopicChars: DEFAULT_MAX_TOPIC_CHARS,
  };
  return config;
});
