import { Label } from "~/components/ui/label";
import { Slider } from "~/components/ui/slider";
import { SettingsDescription, SettingsRow } from "~/pages/settings/layout";
import { charsToLength, lengthName, lengthToChars, type SummaryLength } from "~/pages/settings/source";
import { SourceTypeSettings } from "~/sources/config";
import { DEFAULT_MAX_TOPIC_CHARS, DEFAULT_MAX_TOPICS_PER_FEED, type BskySourceConfig } from "./config";

SourceTypeSettings.method(
  "bsky",
  ({ config, setConfig }: { config: BskySourceConfig; setConfig: (config: BskySourceConfig) => void }) => {
    const bskyConfig = config as BskySourceConfig;

    const maxTopicsPerFeed = bskyConfig.maxTopicsPerFeed ?? DEFAULT_MAX_TOPICS_PER_FEED;
    const maxTopicChars = bskyConfig.maxTopicChars ?? DEFAULT_MAX_TOPIC_CHARS;
    const summaryLength = charsToLength(maxTopicChars);

    return (
      <>
        <SettingsRow>
          <Label>Discussion themes</Label>
          <div className="grow" />
          <div className="text-sm">{maxTopicsPerFeed}</div>
          <div className="w-32 shrink-0">
            <Slider
              defaultValue={[maxTopicsPerFeed]}
              min={1}
              max={10}
              step={1}
              onValueChange={([value]) => setConfig({ ...bskyConfig, maxTopicsPerFeed: value })}
            />
          </div>
        </SettingsRow>
        <SettingsDescription>
          The number of discussion themes to summarize per feed. Themes with the most posts, reposts, replies and likes
          will have the highest priority.
        </SettingsDescription>

        <SettingsRow>
          <Label>Summary length</Label>
          <div className="grow" />
          <div className="text-sm">{lengthName(summaryLength)}</div>
          <div className="w-32 shrink-0">
            <Slider
              defaultValue={[summaryLength]}
              min={1}
              max={3}
              step={1}
              onValueChange={([value]) =>
                setConfig({ ...bskyConfig, maxTopicChars: lengthToChars(value as SummaryLength) })
              }
            />
          </div>
        </SettingsRow>
      </>
    );
  },
);
