import { getDefaultSourceConfig, getReusableSummaryConfigKey, getSourceMeta } from "~/sources/config";

getSourceMeta.method("hn", (_) => ({
  name: "Hacker News",
  description: "Get a summary of top stories from Hacker News.",
  icon: "/images/hn.svg",
  iconMono: "/images/hn-mono.svg",
  singleton: true,
}));

export const MIN_TOPIC_CHARS = 32;
export const DEFAULT_POSTS = 10;
export const DEFAULT_MAX_TOPICS = 3;
export const DEFAULT_MAX_TOPIC_CHARS = 300;

export interface HNSourceConfig extends PrismaJson.SourceConfig {
  posts?: number;
  maxTopicsPerPost?: number;
  maxTopicChars?: number;
}
getDefaultSourceConfig.method("hn", (_) => {
  const config: HNSourceConfig = {
    viewMode: "grid",
    posts: DEFAULT_POSTS,
    maxTopicsPerPost: DEFAULT_MAX_TOPICS,
    maxTopicChars: DEFAULT_MAX_TOPIC_CHARS,
  };
  return config;
});

getReusableSummaryConfigKey.method("hn", (source) => {
  const config = source.config as HNSourceConfig;
  const configKeys = [
    `posts:${config.posts || DEFAULT_POSTS}`,
    `topics:${config.maxTopicsPerPost || DEFAULT_MAX_TOPICS}`,
    `maxChars:${config.maxTopicChars || DEFAULT_MAX_TOPIC_CHARS}`,
  ];
  return configKeys.join("-");
});
