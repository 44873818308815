import { getDefaultSourceConfig, getReusableSummaryConfigKey, getSourceMeta } from "~/sources/config";

getSourceMeta.method("whimsical", (_) => ({
  name: "Whimsical",
  description: "Get a summary of Whimsical posts.",
  icon: "/images/whimsical.svg",
  iconMono: "/images/whimsical-mono.svg",
  authPath: "/auth/whimsical",
  singleton: true,
}));

export const DEFAULT_MAX_POST_CHARS = 300;

export interface WhimsicalSourceConfig extends PrismaJson.SourceConfig {
  onlyJoinedTeams?: boolean;
  includeProjects?: boolean;
  maxPostChars?: number;
}

getDefaultSourceConfig.method("whimsical", (_) => {
  const config: WhimsicalSourceConfig = {
    onlyJoinedTeams: false,
    includeProjects: true,
    maxPostChars: DEFAULT_MAX_POST_CHARS,
    viewMode: "list",
  };
  return config;
});

getReusableSummaryConfigKey.method("whimsical", (source) => {
  const config = source.config as WhimsicalSourceConfig;
  if (config.onlyJoinedTeams) {
    // If onlyJoinedTeams is true, summary will be unique to the user so we can't reuse it. We might implement a smarter
    // approach in future that always generates summaries for all teams and then filters them as separate step.
    return null;
  }
  const configKeys = [`maxChars:${config.maxPostChars || DEFAULT_MAX_POST_CHARS}`];
  if (config.includeProjects) {
    configKeys.push("includeProjects");
  }
  return configKeys.join("-");
});
