import { getDefaultSourceConfig, getReusableSummaryConfigKey, getSourceMeta } from "~/sources/config";

getSourceMeta.method("slack", (_) => ({
  name: "Slack",
  description: "Get a summary of Slack conversations.",
  icon: "/images/slack.svg",
  iconMono: "/images/slack-mono.svg",
  authPath: "/auth/slack",
}));

export const DEFAULT_MAX_TOPICS_PER_CHANNEL = 3;
export const DEFAULT_MAX_TOPIC_CHARS = 300;

export interface SlackSourceConfig extends PrismaJson.SourceConfig {
  onlyJoinedChannels?: boolean;
  maxTopicsPerChannel?: number;
  maxTopicChars?: number;
}

getDefaultSourceConfig.method("slack", (_) => {
  const config: SlackSourceConfig = {
    onlyJoinedChannels: false,
    viewMode: "list",
  };
  return config;
});

getReusableSummaryConfigKey.method("slack", (source) => {
  const config = source.config as SlackSourceConfig;
  if (config.onlyJoinedChannels) {
    // If onlyJoinedChannels is true, summary will be unique to the user so we can't reuse it. We might implement
    // a smarter approach in future that always generates summaries for all teams and then filters them as separate
    // step.
    return null;
  }
  const configKeys = [
    `topics:${config.maxTopicsPerChannel || DEFAULT_MAX_TOPICS_PER_CHANNEL}`,
    `maxChars:${config.maxTopicChars || DEFAULT_MAX_TOPIC_CHARS}`,
  ];
  return configKeys.join("-");
});
